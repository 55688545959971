import React, { useState, useEffect } from "react";
import "./language.css";
import { Breadcrumb, Button, Select, Image } from "antd";
import { addIcon, editIcon, homeIcon, redTrash } from "../../assets";
import { Table } from "antd";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import { RedNotify } from "../../helper/helper";
import Loader from "../../components/loader/loader";
import { useDispatch } from "react-redux";
import { productItem } from "../../redux/userDataSlice";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";
import moment from "moment/moment";
import ModalAddLanguage from "../../components/updateLanguage/modalAddLanguage";

const Language = () => {
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState();
  const [addProduct, setAddProduct] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDes, setShowModalDes] = useState(false);
  const [pDescription, setPdescription] = useState("");
  const [getProduct, setGetProduct] = useState(false);
  const getPreferences = () => {
    let getRes = (res) => {
      // console.log("res of get category", res?.data);
      setProducts(res?.data?.languages);
      // setShowModal(false);
    };

    callApi("GET", routes.getLanguages, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  const DeleteProduct = (item) => {
    setGetProduct(false);
    let getRes = (res) => {
      if (res.status == 400) {
        RedNotify(res.message);
        return;
      }
      // console.log("res of delete object", res);
      setGetProduct(true);
    };

    callApi(
      "DELETE",
      `${routes.deleteLanguage}/${item?._id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const columns = [
    {
      title: "Language",
      dataIndex: "language",
      align: "center",
      className: "role-name-column-header",
      width: 360,
    },
    {
      title: "Edit",
      dataIndex: "edit",
      align: "center",
      className: "action-column-header",
      width: 270,
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "center",
      className: "action-column-header",
      width: 270,
    },
    {
      title: "Created At",
      dataIndex: "created",
      align: "center",
      width: 300,
    },
  ];

  //   Row Data
  const data = products?.map((item, index) => {
    return {
      key: index,
      language: item?.language,
      created: moment(item?.createdAt)
        .local()
        .format("MMMM Do YYYY, h:mm:ss A"),
      delete: (
        <div
          onClick={() => DeleteProduct(item)}
          className="server-roles-trash-btn"
        >
          <img src={redTrash} alt="" />
        </div>
      ),
      edit: (
        <div
          onClick={() => {
            setProduct(item);
            // dispatch(productItem(item));
            setShowModal(true);
            setAddProduct(false);
          }}
          className="product-list-edit-icon"
        >
          <img src={editIcon} />
        </div>
      ),
    };
  });

  useEffect(() => {
    getPreferences();
  }, [showModal, getProduct]);

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      {showModal && (
        <ModalAddLanguage
          showModal={showModal}
          setShowModal={setShowModal}
          item={product}
          setIsLoading={setIsLoading}
          addProduct={addProduct}
          setAddProduct={setAddProduct}
        />
      )}
      {showModalDes && (
        <DescriptionModal
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <Breadcrumb.Item>
          <span className="configure-server-home-icon">
            <img src={homeIcon} alt="home-icon" />
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Languages</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Languages</h1>
        <div
          onClick={() => {
            setAddProduct(true);
            setShowModal(true);
          }}
          className="server-roles-add-btn"
        >
          <img src={addIcon} alt="" />
          <p>Add New Language</p>
        </div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={data?.length > 10 ? true : false}
          className="subscriptionapi-table"
        ></Table>
      </div>
    </div>
  );
};

export default Language;
