import React from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { audios, audios1, categories1, challenges, expertise, language, pictures, posts, privacyPolicy, userIcon, videos } from "../assets";
import "./layout.css";
import Header2 from "../components/header/header";
import { accessToken, refreshToken, userData } from "../redux/userDataSlice";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserList from "../pages/userList/userList";
import { callApi } from "../api/apiCaller";
import routes from "../api/routes";
import { useState, useEffect } from "react";
import Loader from "../components/loader/loader";
import { GreenNotify, RedNotify } from "../helper/helper";
import LandingPage from "../pages/landingPage/landingPage";
import PrivacyPolicy from "../pages/privacypolicy/privacyPolicy";
import Audios from "../pages/audios/audios";
import Category from "../pages/category/category";
import Challenges from "../pages/challenges/challenges";
import Videos from "../pages/videos/videos";
import Pictures from "../pages/pictures/pictures";
import Posts from "../pages/posts/posts";
import Language from "../pages/language/language";
import Expertise from "../pages/expertise/expertise";

const { Header, Content, Footer, Sider } = Layout;
const LayoutDashboard = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = () => {
    let getRes = (res) => {
      if (res.status == 200) {
        GreenNotify(res.message);
        dispatch(userData(null));
        dispatch(accessToken(""));
        dispatch(refreshToken(""));
      } else {
        RedNotify(res.message);
      }
    };

    let body = {
      device: {
        id: localStorage.getItem("deviceId"),
        deviceToken: "xyz",
      },
    };

    callApi("POST", routes.logOut, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Loader loading={isloading} />
      <Sider style={{ background: "#0B1B2D" }} width={280}>
        <div
          style={{
            padding: "2rem 0",
            textAlign: "center",
            color: "white",
            fontSize: "3rem",
            scrollBehavior: "smooth",
            position: "sticky",
            borderBottom: "10px",
          }}
        >
          <h5>Zen With Katya</h5>
        </div>
        <Menu
          style={{ marginTop: "5rem" }}
          inlineCollapsed={true}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
        // items={itemsMain}
        >
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/user-list")}
            icon={<img className="side-bar-icon" src={userIcon} />}
            key="10"
          >
            Users
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/categories")}
            icon={<img className="side-bar-icon" src={categories1} />}
            key="960"
          >
            Categories
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/languages")}
            icon={<img className="side-bar-icon" src={language} />}
            key="961"
          >
            Languages
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/expertise")}
            icon={<img className="side-bar-icon" src={expertise} />}
            key="962"
          >
            Expertise
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/audios")}
            icon={<img className="side-bar-icon" src={audios1} />}
            key="20"
          >
            Audios
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/challenges")}
            icon={<img className="side-bar-icon" src={challenges} />}
            key="30"
          >
            Challenges
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/videos")}
            icon={<img className="side-bar-icon" src={videos} />}
            key="40"
          >
            Videos
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/pictures")}
            icon={<img className="side-bar-icon" src={pictures} />}
            key="50"
          >
            Pictures
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/posts")}
            icon={<img className="side-bar-icon" src={posts} />}
            key="60"
          >
            Community
          </Menu.Item>

          <Menu.Item
            style={{ marginBottom: "3rem" }}
            onClick={() => navigate("/privacyPolicy")}
            icon={<img className="side-bar-icon" src={privacyPolicy} />}
            key='24'
          // className={currentKey === "24" ? "active-menu-item" : ""}
          >
            Privacy Policy
          </Menu.Item>
          <Menu.Item onClick={logOut} key="89">
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header2 />
        <Content
          style={{
            background: "#fff",
          }}
        >
          <Routes>
            <Route path="/" element={<LandingPage />}></Route>
            <Route path="/user-list" element={<UserList />}></Route>
            <Route path="/categories" element={<Category />}></Route>
            <Route path="/audios" element={<Audios />}></Route>
            <Route path="/challenges" element={<Challenges />}></Route>
            <Route path="/videos" element={<Videos />}></Route>
            <Route path="/pictures" element={<Pictures />}></Route>
            <Route path="/posts" element={<Posts />}></Route>
            <Route path="/languages" element={<Language />}></Route>
            <Route path="/expertise" element={<Expertise />}></Route>
            <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDashboard;
